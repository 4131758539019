export const ACTIVITY_GROUP = 'activityGroupList';
export const ONLINE_GOODS = 'onlineGoodsList';
export const STORE = 'storeList';
export const ACTIVITY_RECEIVE_VOUCHER = 'activityReceiveVoucherList';
export const CUSTOMER = 'customerList';
export const SALER_MANAGE = 'salerManageList';
export const ONLINE_ORDER = 'onlineOrderList';
export const ACTIVITY_NEWCOMER = 'activityNewcomerList';
export const CUSTOMER_FILTER = 'customerFilterList';
export const ACTIVITY_ISSUE_VOUCHER = 'activityIssueVoucherList';
export const OFFLINE_GOODS = 'offlineGoodsList';
export const OFFLINE_ORDER = 'offlineOrderList';
export const ACTIVITY_SPECIAL_OFFER = 'activitySpecialOfferList';
export const MATERIAL = 'materialList';
export const TASK_CUSTOMER = 'taskCustomer';
export const TASK_GROUP = 'taskGroup';
export const TASK_MOMENTS = 'taskMoments';
export const CUSTOMER_GROUP = 'customerGroupList';
export const SERVICE = 'serviceList';
export const PRODUCT = 'productList';
export const ACTIVITY_CALENDAR = 'activityCalendarYear';
export const STAFF_NOTICE = 'staffNoticeList';
export const ACTIVITY_CALENDAR_DRAFT = 'activityCalendarDraft';
export const ACTIVITY_CALENDAR_MONTH = 'activityCalendarMonth';
export const ARTICLE = 'articleList';
export const SOP_CUSTOMER = 'sopCustomerIndex';
export const SOP_CUSTOMER_CALENDAR = 'sopCustomerCalendar';
export const SOP_CUSTOMER_SETTING = 'sopCustomerSetting';
export const EXTERNAL_LINK = 'externalLinkList';
export const FISSION = 'fissionIndex';
export const FISSION_INFO_PANEL = 'fissionInfoPanel';
export const FISSION_DRAFT = 'fissionDraft';
export const FISSION_TASK = 'fissionTaskList';
export const ROBOT = 'robotList';
export const PAYMENT_ACCOUNT_BRAND = 'paymentAccountBrandList';
export const PAYMENT_ACCOUNT_BRAND_DETAIL = 'paymentAccountBrandDetail';
export const PAYMENT_ACCOUNT_STORE = 'paymentAccountStoreList';
export const PAYMENT_ACCOUNT_STORE_DETAIL = 'paymentAccountStoreDetail';
export const ACTIVITY_COMBINE_DISCOUNT = 'activityCombineDiscountList';

export default {};
