import { baseService } from './services';

import { formatArrayParamsToQuery } from './utils';
import { PaginationParams, PaginationData, MallType, BlobData } from './types';
import type { GoodsType } from './goods';
import type { CustomerWechatStatus } from './customer';

export interface SalerConfig {
    id: string;
    isOpen: boolean;
    pusherDoor: string;
    pusherName: string;
    pointName: string;
    isPersonnelApply: boolean;
    isApprove: boolean;
    settlementCycle: number;
    applyType: 1 | 2 | 3;
    autoApplyRuleCondition: 1 | 2; // 1:满足任意 2:满足所有
    mallGoodsIdList: string[];
    storeMallGoodsIdList: string[];
    settleType: 1 | 2; // 1:延迟结算 2:固定日期结算
    settleDay: number;
    isAutomaticApply: boolean;
}

/**
 * 获取推手配置
 * @returns
 */
export function getSalerConfig() {
    return baseService.get<SalerConfig>(`/pusher/config`);
}

/**
 * 更新推手配置
 * @param params
 * @returns
 */
export function updateSalerConfig(
    params: Optional<SalerConfig, 'autoApplyRuleCondition' | 'mallGoodsIdList' | 'storeMallGoodsIdList'>,
) {
    return baseService.put(`/pusher/config/update`, params);
}

// 1:客户 2:员工
export type SalerIdentity = 1 | 2;

// 1:启用 2:停用 3:禁用
export type SalerStatus = 1 | 2 | 3;

export interface Saler {
    id: string;
    phone: string;
    pusherIdentity: SalerIdentity;
    status: SalerStatus;
    becomeTime: string;
    totalNumber: number;
    usableNumber: number;
    freezeNumber: number;
    usedNumber: number;
    cancelReduceNumber: number;
    systemReduceNumber: number;
    tokeCouponNumber: number;
    lastApplyTime: string;
    staffName: string;
    userId: string;
    itineraryName: string;
    provinceCode: string;
    provinceName: string;
    cityCode: string;
    cityName: string;
    salesmanName: string;
    pusherTagList: string[];
    estimateNumber: number;
    nickname: string;
    avatar: string;
    addWechat: boolean;
    wechatStatus: CustomerWechatStatus;
    defaultFollowStaffName: string;
    mainStoreName: string;
}

// 1:不参与 2:不活跃 3:一般 4:活跃
export type SalerActiveStatus = 1 | 2 | 3 | 4;

export interface ListSaler {
    id: string;
    phone: string;
    salesmanName: string;
    nickname: string;
    addWechat: boolean;
    wechatStatus: CustomerWechatStatus;
    avatar: string;
    pusherIdentity: SalerIdentity;
    applyStatus: 1 | 2 | 3; // 1:待审核 2:通过 3:拒绝
    lastApplyTime: string;
    status: SalerStatus;
    becomeTime: string;
    usableNumber: number;
    freezeNumber: number;
    bindingNumber: number;
    remarks: string;
    staffName: string;
    userId: string;
    itineraryName: string;
    provinceCode: string;
    provinceName: string;
    cityCode: string;
    cityName: string;
    applyType: 1 | 2 | 3; // 1:人工申请 2:自动申请 3:手工创建
    remainCouponNum: number;
    pusherTagList: string[];
    activeStatus: SalerActiveStatus;
    defaultFollowStaffName: string;
    mainStoreName: string;
}

export type ListSalerParams = PaginationParams &
    Partial<
        Pick<
            ListSaler,
            | 'phone'
            | 'pusherIdentity'
            | 'applyStatus'
            | 'status'
            | 'staffName'
            | 'provinceCode'
            | 'cityCode'
            | 'applyType'
        >
    > & {
        becomeBeginTime?: string;
        becomeEndTime?: string;
        lastApplyBeginTime?: string;
        lastApplyEndTime?: string;
        itineraryId?: string;
        staffIdList?: string[];
        pusherTagIds?: string[];
        defaultFollowStaffIdList?: string[];
        activeStatusList?: SalerActiveStatus[];
        hasNumber?: boolean;
        hasCoupon?: boolean;
    };

/**
 * 获取推手列表
 * @param params
 * @returns
 */
export function getSalerList(params: ListSalerParams) {
    const { staffIdList, pusherTagIds, defaultFollowStaffIdList, activeStatusList, ...reset } = params;
    const query = formatArrayParamsToQuery({ staffIdList, pusherTagIds, defaultFollowStaffIdList, activeStatusList });
    return baseService.get<PaginationData<ListSaler>>(`/pusher/manage/page${query}`, {
        params: reset,
    });
}

interface SalerDataStatisticDTO {
    validPusherNum: number;
    totalCustomerNum: number;
    totalUsableNumber: number;
    totalRemainCouponNum: number;
}

export type SalerDataStatisticVO = SalerDataStatisticDTO;

/**
 * 获取推手数据统计
 * @param params
 * @returns
 */
export function getSalerDataStatistic(params: Omit<ListSalerParams, 'page.currentPage' | 'page.pageSize'>) {
    const { staffIdList, pusherTagIds, defaultFollowStaffIdList, activeStatusList, ...reset } = params;
    const query = formatArrayParamsToQuery({ staffIdList, pusherTagIds, defaultFollowStaffIdList, activeStatusList });
    return baseService.get<SalerDataStatisticVO>(`/pusher/pusherData${query}`, {
        params: reset,
    });
}

export interface AppRoveParams {
    id: string;
    applyStatus: number;
    remarks?: string;
}

/**
 * 审核推手
 * @param params
 * @returns
 */
export function approveSaler(params: AppRoveParams) {
    return baseService.put(`/pusher/approve`, params, { loading: false });
}

/**
 * 获取推手状态
 * @param params
 * @returns
 */
export function updateSalerStatus(id: string, status: ListSaler['status']) {
    const params = { id, status };
    return baseService.put(`/pusher/statusUpdate`, params, { loading: false });
}

/**
 * 获取推手
 * @param id
 * @returns
 */
export function getSaler(id: ListSaler['id']) {
    return baseService.get<Saler>(`/pusher/infos/${id}`);
}

export interface BindRecord {
    id: string;
    phone: string;
    bindingTime: string;
    provideNumber: number;
    isPusher: boolean;
    avatar: string;
    customerName: string;
    nickname: string;
    addWechat: boolean;
    wechatStatus: CustomerWechatStatus;
    counselorStaff: string;
    defaultFollowStaffName: string;
    mainStoreName: string;
    itineraryName: string;
}

export type BindListParams = PaginationParams &
    Partial<Pick<BindRecord, 'phone' | 'isPusher' | 'customerName' | 'counselorStaff' | 'defaultFollowStaffName'>> & {
        id: Saler['id'];
        bindingBeginTime?: string;
        bindingEndTime?: string;
        customerItineraryIdList?: string[];
    };

/**
 * 获取客户绑定列表
 * @param params
 * @returns
 */
export function getCustomerBindList(params: BindListParams) {
    const { customerItineraryIdList, ...reset } = params;
    const query = formatArrayParamsToQuery({ customerItineraryIdList });
    return baseService.get<PaginationData<BindRecord>>(`/pusher/customerBinding/page${query}`, {
        params: reset,
    });
}

// 1:下单获取 2:退单扣除 3:兑换消耗 4:系统扣除 5:团购下单奖励 6:团购满员成团奖励 7:特惠下单奖励 8:团购退单扣除 9:团购成团退单扣除 10:特惠退单扣除 11:新人下单奖励 12:新人下单扣除 13:新人团购奖励 14:新人团购扣除 15:新人特惠奖励 16:新人特惠扣除 17:参与抢券奖励 18:新人抢券奖励 19:品项券邀请奖励 20:优惠券邀请奖励 21:邀请新人奖励 22:点赞奖励 23:新人点赞奖励 24:系统增加 25:参与抢券扣除 26:新人抢券扣除 27:优惠券邀请扣除 28:邀请新人扣除
export type PointChangeType =
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24
    | 25
    | 26
    | 27
    | 28;

export interface PointInOut {
    id: string;
    operateType: 0 | 1; // 0:减分 1:加分
    name: string;
    phone: string;
    number: number;
    produceTime: string;
    changeType: PointChangeType;
    productName: string;
    productType: GoodsType;
    orderNo: string;
    settleAccountsStatus: 0 | 1 | 2 | 3; // 0:待结算 1:已结算 2:已取消 3:预计结算
    placeOrderTime: string;
    exchangeSerialNumber: string;
    exchangeTime: string;
    writeOffTime: string;
    chargebackTime: string;
    writeOffStoreName: string;
    chargebackStoreName: string;
    settleAccountsTime: string;
    writeOffStaffName: string;
    chargebackStaffName: string;
    storeType: MallType;
    contentName: string;
    // 1:日常下单 2:兑换奖励 3:系统行为 4:商品推广 5:团购助力 6:特惠助力 7:抢券助力 8:邀请拉新 9:软文拉新
    scene: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
}

export type ListPointInOut = Pick<
    PointInOut,
    | 'id'
    | 'operateType'
    | 'produceTime'
    | 'changeType'
    | 'productName'
    | 'number'
    | 'settleAccountsStatus'
    | 'settleAccountsTime'
    | 'storeType'
    | 'contentName'
    | 'scene'
> & {
    customerPhone: string;
};

export type PointInOutListParams = PaginationParams &
    Partial<Pick<ListPointInOut, 'changeType' | 'settleAccountsStatus' | 'scene' | 'storeType'>> & { id: Saler['id'] };

/**
 * 获取积分收支列表
 * @param params
 * @returns
 */
export function getPointInOutList(params: PointInOutListParams) {
    return baseService.get<PaginationData<ListPointInOut>>(`/pusher/receivePay/page`, { params });
}

/**
 * 获取积分收支详情
 * @param params
 * @returns
 */
export function getPointInOut(id: ListSaler['id']) {
    return baseService.get<PointInOut>(`/pusher/receivePay/${id}`);
}

export interface UpdatePointParams {
    id: ListSaler['id'];
    number: number;
    type: 1 | 2; // 1:减分 2:加分
}

/**
 * 更新积分
 * @param id
 * @returns
 */
export function updatePoint(params: UpdatePointParams) {
    return baseService.put(`/pusher/deduct`, params);
}

// 1:永久 2:兑换后几天
export type PointGoodsEffectiveType = 1 | 2;

// 1:未使用 2:已使用 3:已过期 4:已转赠
export type PointGoodsUseStatus = 1 | 2 | 3 | 4;

export interface PointGoodsRedeem {
    id: string;
    name: string;
    phone: string;
    exchangeTime: string;
    exchangeSerialNumber: string;
    pointProductName: string;
    amount: number;
    number: number;
    effectiveType: PointGoodsEffectiveType;
    endTime: string;
    writeOffStoreName: string;
    useStatus: PointGoodsUseStatus;
    writeOffTime: string;
    writeOffNumber: string;
    verificationCode: string;
    writeOffStaffName: string;
    getCustomerName: string;
    getCustomerPhone: string;
    getTime: string;
}

export type ListPointGoodsRedeem = Pick<
    PointGoodsRedeem,
    'id' | 'phone' | 'pointProductName' | 'number' | 'exchangeTime' | 'writeOffStoreName' | 'useStatus'
>;

export type PointGoodsRedeemListParams = PaginationParams &
    Partial<Pick<ListPointGoodsRedeem, 'writeOffStoreName' | 'useStatus'>> & {
        id: Saler['id'];
        exchangeBeginTime?: string;
        exchangeEndTime?: string;
    };

/**
 * 获取积分商品列表
 * @param params
 * @returns
 */
export function getPointGoodsRedeemList(params: PointGoodsRedeemListParams) {
    return baseService.get<PaginationData<ListPointGoodsRedeem>>(`/pusher/exchange/page`, { params });
}

/**
 * 获取积分商品
 * @param id
 * @returns
 */
export function getPointGoodsRedeem(id: ListPointGoodsRedeem['id']) {
    return baseService.get<PointGoodsRedeem>(`/pusher/exchange/${id}`);
}

export interface PointGoods {
    id: string;
    name: string;
    subtitle: string;
    pictureUrl: string;
    number: number;
    effectiveType: PointGoodsEffectiveType;
    day: number;
    detailUrls: string[];
    exchangeAmount: number;
}

export type ListPointGoods = Pick<PointGoods, 'id' | 'name' | 'number' | 'exchangeAmount' | 'pictureUrl'> & {
    status: number; // 0:下架 1:上架
    createTime: string;
};

export type PointGoodsListParams = PaginationParams &
    Pick<ListPointGoods, 'name' | 'status'> & {
        createBeginTime?: string;
        createEndTime?: string;
    };

/**
 * 获取积分商品列表
 * @param params
 * @returns
 */
export function getPointGoodsList(params: PointGoodsListParams) {
    return baseService.get<PaginationData<ListPointGoods>>(`/pusher/pointProduct/page`, { params });
}

/**
 * 获取积分商品
 * @param id
 * @returns
 */
export function getPointGoods(id: ListPointGoods['id']) {
    return baseService.get<PointGoods>(`/pusher/pointProduct/${id}`);
}

export type PointGoodsActionParams = Partial<Pick<PointGoods, 'id'>> & Omit<PointGoods, 'id' | 'exchangeAmount'>;

/**
 * 添加积分商品
 * @param params
 * @returns
 */
export function addPointGoods(params: PointGoodsActionParams) {
    return baseService.post(`/pusher/pointProduct/save`, params);
}

/**
 * 更新积分商品
 * @param params
 * @returns
 */
export function updatePointGoods(params: PointGoodsActionParams) {
    return baseService.put(`/pusher/pointProduct/update`, params);
}

/**
 * 更新积分商品状态
 * @param id
 * @param status
 * @returns
 */
export function updatePointGoodsStatus(id: ListPointGoods['id'], status: ListPointGoods['status']) {
    const params = { id, status };
    return baseService.put(`/pusher/pointProduct/statusUpdate`, params);
}

export type RedeemRecord = Pick<PointGoods, 'name' | 'number' | 'day' | 'effectiveType' | 'day'> & {
    id: string;
    phone: string;
    exchangeTime: string;
    exchangeSerialNumber: string;
    pointProductName: string;
    amount: number;
    endTime: string;
    writeOffStoreName: string;
    useStatus: PointGoodsUseStatus;
    writeOffTime: string;
    writeOffNumber: string;
    verificationCode: string;
    writeOffStaffName: string;
};

export type ListRedeemRecord = Pick<
    RedeemRecord,
    'id' | 'pointProductName' | 'phone' | 'number' | 'exchangeTime' | 'writeOffStoreName' | 'useStatus'
> & {
    customerName: string;
    nickname: string;
    avatar: string;
    addWechat: boolean;
    wechatStatus: CustomerWechatStatus;
    counselorStaff: string;
    defaultFollowStaffName: string;
    mainStoreName: string;
};

export type RedeemRecordListParams = PaginationParams &
    Partial<
        Pick<ListRedeemRecord, 'pointProductName' | 'useStatus' | 'phone' | 'writeOffStoreName' | 'customerName'>
    > & {
        exchangeBeginTime?: string;
        exchangeEndTime?: string;
    };

/**
 * 获取积分商品兑换记录列表
 * @param params
 * @returns
 */
export function getRedeemRecordList(params: RedeemRecordListParams) {
    return baseService.get<PaginationData<ListRedeemRecord>>(`/pusher/exchange/page`, { params });
}

/**
 * 获取积分商品兑换记录
 * @param id
 * @returns
 */
export function getRedeemRecord(id: ListRedeemRecord['id']) {
    return baseService.get<RedeemRecord>(`/pusher/exchange/${id}`);
}

/**
 * 添加推手
 * @param id
 * @returns
 */
export function addSaler(id: string) {
    return baseService.post(`/pusher/addPusher?customerId=${id}`, null);
}

// 1:优惠券 2:品项券
export type SalerVoucherType = 1 | 2;

// 1:待转赠 2:已失效 3:未使用 4:已使用 5:已过期
export type SalerVoucherStatus = 1 | 2 | 3 | 4 | 5;

interface SalerVoucherDTO {
    id: string;
    couponName: string;
    couponType: SalerVoucherType;
    couponId: string;
    couponContentList: string[];
    pusherCouponState: SalerVoucherStatus;
    sendValidPeriod: string;
    acquireCustomerPhone: string;
    acquireTime: string;
    useValidPeriod: string;
    periodValidity: number;
    periodValidityStartDate: string;
    periodValidityEndDate: string;
}

export type SalerVoucherVO = SalerVoucherDTO;

export type SalerVoucherListParams = PaginationParams & {
    pusherId: string;
    couponName?: string;
    couponType?: SalerVoucherType;
    pusherCouponState?: SalerVoucherStatus;
    acquireCustomerPhone?: string;
    sendValidPeriodStart?: string;
    sendValidPeriodEnd?: string;
    acquireTimeStart?: string;
    acquireTimeEnd?: string;
    useValidPeriodStart?: string;
    useValidPeriodEnd?: string;
};

/**
 * 获取积分收支列表
 * @param params
 * @returns
 */
export function getSalerVoucherList(params: SalerVoucherListParams) {
    return baseService.get<PaginationData<SalerVoucherVO>>(`/pusher/listPusherCoupon`, { params });
}

/**
 * 下载推手发布券数据
 * @param id
 * @returns
 */
export function downloadIssueVoucherData(id: string) {
    return baseService.get<BlobData>(`/salesman/couponRelease/downloadData/${id}`, { responseType: 'blob' });
}

interface SalerChannelTagDTO {
    tagId: string;
    corpTagId: string;
    tagName: string;
}

export type SalerChannelTagVO = SalerChannelTagDTO;

/**
 * 获取推手渠道标签
 * @param id
 * @returns
 */
export function getSalerChannelTags() {
    return baseService.get<SalerChannelTagVO[]>(`/tags/pusherChannel`, { loading: false });
}

/**
 * 导出推手数据
 * @param params
 * @returns
 */
export function exportSalerData(params: Omit<ListSalerParams, 'page.currentPage' | 'page.pageSize'>) {
    const { staffIdList, pusherTagIds, defaultFollowStaffIdList, activeStatusList, ...reset } = params;
    const query = formatArrayParamsToQuery({ staffIdList, pusherTagIds, defaultFollowStaffIdList, activeStatusList });
    return baseService.get<BlobData>(`/pusher/manage/export${query}`, {
        params: reset,
        responseType: 'blob',
    });
}

export default {};
