export function formatArrayParamsToQuery(params: Record<string, any[] | undefined>) {
    let query = '';
    const keys = Object.keys(params);
    keys.forEach((key) => {
        const value = params[key];
        if (value) {
            query = value.reduce((acc, cur) => {
                let str = `${acc}${!acc ? '' : '&'}`;
                str += `${key}=${cur}`;
                return str;
            }, query);
        }
    });
    return query === '' ? '' : `?${query}`;
}

export default {};
