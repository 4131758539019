import { baseService } from './services';

import { PaginationParams, PaginationData, RequestConfig } from './types';

export interface Department {
    id: string;
    parentId: string;
    deptName: string;
    level: number;
}

/**
 * 获取部门列表
 * @returns
 */
export function getDepartmentList() {
    return baseService.get<Department[]>(`/department/list`);
}

// 顾问 ｜ 服务操作人 ｜ 店长 | 管理员
export type RoleCode = 'ROLE_STAFF_COUNSELOR' | 'ROLE_SERVICE_OPERATOR' | 'ROLE_STORE_MANAGER' | 'ROLE_STAFF_ADMIN';

// 1:已激活 2:已禁用 4:未激活 5:退出企业 6:已过期
export type StaffStatus = 1 | 2 | 4 | 5 | 6;

export interface Staff {
    id: string;
    phone: string;
    gender: 0 | 1; // 0:女 1:男
    staffName: string;
    status: StaffStatus;
    userId: string;
    departmentList: {
        deptName: string;
        staffId: string;
    }[];
    storeRoleVOList: { storeId: string; storeName: string; isMainStore: boolean; roleCodeList: RoleCode[] }[];
    mainStoreName: 'string';
}

export interface ListStaff {
    id: string;
    staffName: string;
    status: StaffStatus;
    userId: string;
    phone: string;
    mainStoreName: string;
    departmentNameList: string[];
    storeNameList: string[];
    roleNameList: string[];
    isAdmin: boolean;
    expireTime: string;
    hasAdminSeat: boolean;
    updateUserName: string;
}

export type ListParams = PaginationParams &
    Partial<Partial<Pick<ListStaff, 'staffName'>>> & {
        departmentId?: string;
        storeId?: string;
        roleCode?: string;
        mainStoreId?: string;
        status?: ListStaff['status'] | ListStaff['status'][];
        excludeStaffIdList?: string[];
    };

/**
 * 获取员工列表
 * @param params
 * @returns
 */
export function getStaffList(params: ListParams) {
    const { status, excludeStaffIdList, ...reset } = params;
    const _status = Array.isArray(status) ? status : [status];
    let query = '';
    _status?.forEach((item) => {
        query += `${query ? '&' : ''}status=${item}`;
    });
    excludeStaffIdList?.forEach((item) => {
        query += `${query ? '&' : ''}excludeStaffIdList=${item}`;
    });
    return baseService.get<PaginationData<ListStaff>>(`/staff/page${query ? `?${query}` : ''}`, { params: reset });
}

export interface StaffRole {
    roleType: 1 | 2; // 1:管理后台 2:B端小程序
    roleCode: RoleCode;
    roleName: string;
}

/**
 * 获取员工角色列表
 * @returns
 */
export function getStaffRoleList() {
    return baseService.get<StaffRole[]>(`/staff/roleList`, { loading: false });
}

/**
 * 获取员工信息
 * @param id
 * @returns
 */
export function getStaff(id: ListStaff['id']) {
    return baseService.get<Staff>(`/staff/${id}`);
}

/**
 * 更新员工信息
 * @param params
 * @returns
 */
export function updateStaff(params: Staff) {
    return baseService.put(`/staff`, params);
}

export interface StaffLeaf {
    id: string;
    name: string;
    level: number;
    children?: StaffLeaf[];
}

/**
 * 获取员工树
 * @param codes 角色code集合
 * @param status 角色状态集合
 * @returns
 */
export function getStaffTree(codes?: string[], status?: StaffStatus[], config?: RequestConfig) {
    let query = '';
    codes?.forEach((item) => {
        query += `${query ? '&' : ''}roleCodeList=${item}`;
    });
    status?.forEach((item) => {
        query += `${query ? '&' : ''}statusList=${item}`;
    });
    return baseService.get<StaffLeaf[]>(`/staff/getStaffTree${query ? `?${query}` : ''}`, config);
}

export interface HasReportStaffTreeParams {
    type: 1 | 2; // 1:每日 2:月度
    dataTime: string; // 选择月份时默认日期为1号，如 2023-05-01
    storeId?: string;
    staffId?: string;
    _belong: 1 | 2; // 1:顾问 2:操作人
}

/**
 * 获取有报表的员工树
 * @param params
 * @returns
 */
export function getHasReportStaffTree(params: HasReportStaffTreeParams) {
    const { _belong, ...reset } = params;
    const url = _belong === 1 ? `/businessReport/listStoreAndStaff` : `/operatorReport/listStoreAndStaff`;
    return baseService.get<StaffLeaf[]>(url, { params: reset });
}

interface StaffRoleConfigDTO {
    roleCode: RoleCode;
    roleName: string;
    roleBaseName: string;
    description: string;
}

export type StaffRoleConfigVO = StaffRoleConfigDTO;

/**
 * 获取员工角色配置
 * @returns
 */
export function getStaffRoleConfig() {
    return baseService.get<StaffRoleConfigVO[]>(`/role-management`, { loading: false });
}

/**
 * 更新员工角色配置
 * @param roles
 * @returns
 */
export function updateStaffRoleConfig(roles: { roleCode: string; roleName: string }[]) {
    return baseService.post(`/role-management`, roles);
}

interface TransferStaffParams {
    staffId: string;
    replaceStaffId: string;
    sync?: boolean;
}

/**
 * 转移员工
 * @param params
 * @returns
 */
export function transferStaff(params: TransferStaffParams) {
    return baseService.post(`/replace/onJobReplaceStaff`, params);
}

interface StaffAssetsDTO {
    customerNum: number;
    abnormalCustomerNum: number;
    followCustomerNum: number;
    corporationChatNum: number;
    otherCustomerNum: number;
}

export type StaffAssetsVO = StaffAssetsDTO;

/**
 * 获取员工资产(客户/客户群)
 * @param id
 * @returns
 */
export function getStaffAssets(id: string) {
    return baseService.get<StaffAssetsVO>(`/replace/onJobReplaceStaffCheck/${id}`);
}

interface TransferableStaffDTO {
    id: string;
    userId: string;
    staffName: string;
    phone: string;
    mainStoreId: string;
    mainStoreName: string;
    addCustomer: boolean;
}

export type TransferableStaffVO = TransferableStaffDTO;

export type TransferableStaffListParams = PaginationParams & {
    customerId: string;
    staffName?: string;
    mainStoreId?: string;
    status?: StaffStatus[];
    excludeStaffIdList?: string[];
    roleCode?: RoleCode;
};

/**
 * 获取客户可转移至的员工列表
 * @param params
 * @returns
 */
export function getTransferableStaffList(params: TransferableStaffListParams) {
    const { status, excludeStaffIdList, ...reset } = params;
    let query = '';
    status?.forEach((item) => {
        query += `${query ? '&' : ''}status=${item}`;
    });
    excludeStaffIdList?.forEach((item) => {
        query += `${query ? '&' : ''}excludeStaffIdList=${item}`;
    });
    return baseService.get<PaginationData<TransferableStaffVO>>(
        `/staff/queryReplaceStaffPage${query ? `?${query}` : ''}`,
        {
            params: reset,
        },
    );
}

/**
 * 迁移员工账号
 * @returns
 */
export function migrateStaffAccount(source: string, target: string) {
    const params = {
        handoverUserid: source,
        takeoverUserid: target,
    };
    return baseService.post(`/staff/transferLicense`, params);
}

export default {};
